/**
 * @fileoverview gRPC-Web generated client stub for symbol
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.4
// source: src/grpc/iuxprotos/symbol.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.symbol = require('./symbol_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.symbol.SymbolClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.symbol.SymbolPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.symbol.SymbolReply>}
 */
const methodDescriptor_Symbol_ListArray = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/ListArray',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.symbol.SymbolReply,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SymbolReply.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SymbolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SymbolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.listArray =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/ListArray',
      request,
      metadata || {},
      methodDescriptor_Symbol_ListArray,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SymbolReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.listArray =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/ListArray',
      request,
      metadata || {},
      methodDescriptor_Symbol_ListArray);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.symbol.SymbolReplyNew>}
 */
const methodDescriptor_Symbol_ListArrayNew = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/ListArrayNew',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.symbol.SymbolReplyNew,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SymbolReplyNew.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SymbolReplyNew)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SymbolReplyNew>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.listArrayNew =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/ListArrayNew',
      request,
      metadata || {},
      methodDescriptor_Symbol_ListArrayNew,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SymbolReplyNew>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.listArrayNew =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/ListArrayNew',
      request,
      metadata || {},
      methodDescriptor_Symbol_ListArrayNew);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.TickRequest,
 *   !proto.symbol.TickData>}
 */
const methodDescriptor_Symbol_OnTick = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/OnTick',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.symbol.TickRequest,
  proto.symbol.TickData,
  /**
   * @param {!proto.symbol.TickRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.TickData.deserializeBinary
);


/**
 * @param {!proto.symbol.TickRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.TickData>}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.onTick =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/symbol.Symbol/OnTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_OnTick);
};


/**
 * @param {!proto.symbol.TickRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.TickData>}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolPromiseClient.prototype.onTick =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/symbol.Symbol/OnTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_OnTick);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.TickRequest,
 *   !proto.symbol.SubscribeReply>}
 */
const methodDescriptor_Symbol_TickSubscribeArray = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/TickSubscribeArray',
  grpc.web.MethodType.UNARY,
  proto.symbol.TickRequest,
  proto.symbol.SubscribeReply,
  /**
   * @param {!proto.symbol.TickRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SubscribeReply.deserializeBinary
);


/**
 * @param {!proto.symbol.TickRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SubscribeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SubscribeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.tickSubscribeArray =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/TickSubscribeArray',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickSubscribeArray,
      callback);
};


/**
 * @param {!proto.symbol.TickRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SubscribeReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.tickSubscribeArray =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/TickSubscribeArray',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickSubscribeArray);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.SymbolName,
 *   !proto.symbol.SubscribeReply>}
 */
const methodDescriptor_Symbol_TickSubscribe = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/TickSubscribe',
  grpc.web.MethodType.UNARY,
  proto.symbol.SymbolName,
  proto.symbol.SubscribeReply,
  /**
   * @param {!proto.symbol.SymbolName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SubscribeReply.deserializeBinary
);


/**
 * @param {!proto.symbol.SymbolName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SubscribeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SubscribeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.tickSubscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/TickSubscribe',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickSubscribe,
      callback);
};


/**
 * @param {!proto.symbol.SymbolName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SubscribeReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.tickSubscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/TickSubscribe',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickSubscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.TickRequest,
 *   !proto.symbol.SubscribeReply>}
 */
const methodDescriptor_Symbol_TickUnsubscribeArray = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/TickUnsubscribeArray',
  grpc.web.MethodType.UNARY,
  proto.symbol.TickRequest,
  proto.symbol.SubscribeReply,
  /**
   * @param {!proto.symbol.TickRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SubscribeReply.deserializeBinary
);


/**
 * @param {!proto.symbol.TickRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SubscribeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SubscribeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.tickUnsubscribeArray =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/TickUnsubscribeArray',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickUnsubscribeArray,
      callback);
};


/**
 * @param {!proto.symbol.TickRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SubscribeReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.tickUnsubscribeArray =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/TickUnsubscribeArray',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickUnsubscribeArray);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.SymbolName,
 *   !proto.symbol.SubscribeReply>}
 */
const methodDescriptor_Symbol_TickUnsubscribe = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/TickUnsubscribe',
  grpc.web.MethodType.UNARY,
  proto.symbol.SymbolName,
  proto.symbol.SubscribeReply,
  /**
   * @param {!proto.symbol.SymbolName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SubscribeReply.deserializeBinary
);


/**
 * @param {!proto.symbol.SymbolName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SubscribeReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SubscribeReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.tickUnsubscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/TickUnsubscribe',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickUnsubscribe,
      callback);
};


/**
 * @param {!proto.symbol.SymbolName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SubscribeReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.tickUnsubscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/TickUnsubscribe',
      request,
      metadata || {},
      methodDescriptor_Symbol_TickUnsubscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.SymbolName,
 *   !proto.symbol.TickData>}
 */
const methodDescriptor_Symbol_OnSymbolTick = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/OnSymbolTick',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.symbol.SymbolName,
  proto.symbol.TickData,
  /**
   * @param {!proto.symbol.SymbolName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.TickData.deserializeBinary
);


/**
 * @param {!proto.symbol.SymbolName} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.TickData>}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.onSymbolTick =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/symbol.Symbol/OnSymbolTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_OnSymbolTick);
};


/**
 * @param {!proto.symbol.SymbolName} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.TickData>}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolPromiseClient.prototype.onSymbolTick =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/symbol.Symbol/OnSymbolTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_OnSymbolTick);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.symbol.StatData>}
 */
const methodDescriptor_Symbol_OnStat = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/OnStat',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.symbol.StatData,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.StatData.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.StatData>}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.onStat =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/symbol.Symbol/OnStat',
      request,
      metadata || {},
      methodDescriptor_Symbol_OnStat);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.StatData>}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolPromiseClient.prototype.onStat =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/symbol.Symbol/OnStat',
      request,
      metadata || {},
      methodDescriptor_Symbol_OnStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.symbol.LastTickReply>}
 */
const methodDescriptor_Symbol_LastTick = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/LastTick',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.symbol.LastTickReply,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.LastTickReply.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.LastTickReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.LastTickReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.lastTick =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/LastTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_LastTick,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.LastTickReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.lastTick =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/LastTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_LastTick);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.LastTickRequest,
 *   !proto.symbol.TickData>}
 */
const methodDescriptor_Symbol_GetLastTick = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/GetLastTick',
  grpc.web.MethodType.UNARY,
  proto.symbol.LastTickRequest,
  proto.symbol.TickData,
  /**
   * @param {!proto.symbol.LastTickRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.TickData.deserializeBinary
);


/**
 * @param {!proto.symbol.LastTickRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.TickData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.TickData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.getLastTick =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/GetLastTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_GetLastTick,
      callback);
};


/**
 * @param {!proto.symbol.LastTickRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.TickData>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.getLastTick =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/GetLastTick',
      request,
      metadata || {},
      methodDescriptor_Symbol_GetLastTick);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.symbol.SymbolReply>}
 */
const methodDescriptor_Symbol_Traded = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/Traded',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.symbol.SymbolReply,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SymbolReply.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SymbolReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SymbolReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.traded =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/Traded',
      request,
      metadata || {},
      methodDescriptor_Symbol_Traded,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SymbolReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.traded =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/Traded',
      request,
      metadata || {},
      methodDescriptor_Symbol_Traded);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.symbol.SymbolReplyNew>}
 */
const methodDescriptor_Symbol_TradedNew = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/TradedNew',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.symbol.SymbolReplyNew,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SymbolReplyNew.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SymbolReplyNew)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SymbolReplyNew>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.tradedNew =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/TradedNew',
      request,
      metadata || {},
      methodDescriptor_Symbol_TradedNew,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SymbolReplyNew>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.tradedNew =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/TradedNew',
      request,
      metadata || {},
      methodDescriptor_Symbol_TradedNew);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.symbol.SymbolSessionRequest,
 *   !proto.symbol.SymbolSessionReply>}
 */
const methodDescriptor_Symbol_SessionTrade = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/SessionTrade',
  grpc.web.MethodType.UNARY,
  proto.symbol.SymbolSessionRequest,
  proto.symbol.SymbolSessionReply,
  /**
   * @param {!proto.symbol.SymbolSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SymbolSessionReply.deserializeBinary
);


/**
 * @param {!proto.symbol.SymbolSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SymbolSessionReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SymbolSessionReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.sessionTrade =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/SessionTrade',
      request,
      metadata || {},
      methodDescriptor_Symbol_SessionTrade,
      callback);
};


/**
 * @param {!proto.symbol.SymbolSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SymbolSessionReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.sessionTrade =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/SessionTrade',
      request,
      metadata || {},
      methodDescriptor_Symbol_SessionTrade);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.symbol.SymbolGroupReply>}
 */
const methodDescriptor_Symbol_SymbolGroup = new grpc.web.MethodDescriptor(
  '/symbol.Symbol/SymbolGroup',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.symbol.SymbolGroupReply,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.symbol.SymbolGroupReply.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.symbol.SymbolGroupReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.symbol.SymbolGroupReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.symbol.SymbolClient.prototype.symbolGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/symbol.Symbol/SymbolGroup',
      request,
      metadata || {},
      methodDescriptor_Symbol_SymbolGroup,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.symbol.SymbolGroupReply>}
 *     Promise that resolves to the response
 */
proto.symbol.SymbolPromiseClient.prototype.symbolGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/symbol.Symbol/SymbolGroup',
      request,
      metadata || {},
      methodDescriptor_Symbol_SymbolGroup);
};


module.exports = proto.symbol;

